import { Box, Button, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { http } from '../../api/http'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import GetApp from '@material-ui/icons/GetApp'
import Spinner from '../../shared/components/Spinner'
import PhotoDialog from '../../shared/components/PhotoDialog'
import moment from 'moment'
const useStyles = makeStyles((theme) => ({
	downloadBtn: {
		marginTop: '10px',
		alignItems: 'center',
		textTransform: 'none',
	},
}))

const TicketDetails = () => {
	const classes = useStyles()
	const { id } = useParams()
	const [selectedPhoto, setSelectedPhoto] = useState()
	const [dialogOpen, setDialogOpen] = useState(false)
	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const {
		pondDetails,
		animalVariety,
		type: animalType,
		timeSlot,
		issuesDetails,
		waterReport,
		pondPicUrls,
		animalPicUrls,
		ticketId,
		waterReportFileUrl,
	} = data
	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	function getData() {
		http
			.get(`/request-help/${id}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	const getDoc = (doc) => {
		const startDate = moment(doc)
		const endDate = moment()
		return endDate.diff(startDate, 'days')
	}
	if (loading) return <Spinner />

	return (
		<div>
			<Box display='flex' alignItems='center' gridGap={20}>
				<Link to='/tickets/active'>
					<KeyboardBackspaceIcon />
				</Link>
				<Typography>{`Issue details (Issue Id: ${ticketId})`}</Typography>
			</Box>
			<Box mb={2}>
				<Typography variant='h5' component='h1'>
					Issue Details
				</Typography>
			</Box>

			<Box display='flex' flexDirection='column' gridGap={15}>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Selected Ponds/Tanks ({pondDetails?.length})
					</Typography>
					<Box>
						{pondDetails?.map((item, index) => {
							if (index < 3)
								return (
									<Box key={item.id}>
										<Typography
											variant='body1'
											component='p'
											color='textPrimary'
										>
											{`Pond ${index + 1}`}
										</Typography>
										<Box display='flex' gridGap={10}>
											<Typography
												variant='body2'
												component='p'
												color='textPrimary'
											>
												{`DOC ${getDoc(item.doc)} Days |`}
											</Typography>
											<Typography
												variant='body2'
												component='p'
												color='textPrimary'
											>
												{item.address}
											</Typography>
										</Box>
									</Box>
								)
						})}
					</Box>
				</Box>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Animal Type and Variety
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{animalType} | {animalVariety}
					</Typography>
				</Box>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Issue Type
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{issuesDetails?.map((item) => (
							<li>{item.name}</li>
						))}
					</Typography>
				</Box>
				{/* <Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Issue Description
					</Typography>
					<Box mt={1}>
						<audio className={classes.audioPlayer} controls>
							<source src='horse.ogg' type='audio/ogg' />
							<source src='horse.mp3' type='audio/mpeg' />
							Your browser does not support the audio element.
						</audio>
					</Box>
				</Box> */}
				{pondPicUrls?.length > 0 && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Pond Image
						</Typography>
						<Box mt={1} display='flex' gridGap={20}>
							{pondPicUrls?.length > 0 &&
								pondPicUrls?.map((item, index) => (
									<img
										src={item}
										alt={item}
										width={300}
										height={200}
										onClick={() => {
											setSelectedPhoto(item)
											setDialogOpen(true)
										}}
									/>
								))}
						</Box>
					</Box>
				)}
				{animalPicUrls?.length > 0 && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Animal Images
						</Typography>
						<Box mt={1} display='flex' gridGap={20}>
							{animalPicUrls?.length > 0 &&
								animalPicUrls?.map((item, index) => (
									<img
										src={item}
										alt={item}
										width={300}
										height={200}
										onClick={() => {
											setSelectedPhoto(item)
											setDialogOpen(true)
										}}
									/>
								))}
						</Box>
					</Box>
				)}
				{waterReportFileUrl?.length && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Water Report
						</Typography>
						{waterReportFileUrl?.map((item) => {
							return (
								<img
									src={item}
									alt={item}
									width={300}
									height={200}
									onClick={() => {
										setSelectedPhoto(item)
										setDialogOpen(true)
									}}
								/>
							)
						})}
					</Box>
				)}
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Scheduled Call
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{timeSlot}
					</Typography>
				</Box>
			</Box>
			<PhotoDialog
				setOpen={setDialogOpen}
				open={dialogOpen}
				imageUrl={selectedPhoto}
			/>
		</div>
	)
}

export default TicketDetails
