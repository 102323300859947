import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import defaultImage from '../../assets/images/default-image.png'
import PhotoDialog from '../../shared/components/PhotoDialog'
import { useState } from 'react'
import moment from 'moment'

const useStyles = makeStyles({
	root: {
		display: 'flex',
		background:
			'linear-gradient(108deg, rgba(0, 147, 233, 0.2) 0%, rgba(128, 208, 199, 0.2) 100%) 0% 0% no-repeat',
		marginBottom: '10px',
		borderRadius: '10px',
		minHeight: '250px',
		alignItems: 'center',
		padding: '0 10px',
	},
	grid: {
		display: 'flex',
		justifyContent: 'space between',
		gap: '50px',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		gap: '15px',
	},
})

export default function Pond({ data, index }) {
	const classes = useStyles()
	const [openDialog, setOpenDialog] = useState(false)

	const getDoc = () => {
		const startDate = moment(data?.doc)
		const endDate = moment()
		return endDate.diff(startDate, 'days')
	}

	return (
		<Card className={classes.root} variant='outlined'>
			<Box>
				<CardMedia
					onClick={() => setOpenDialog(true)}
					component='img'
					alt=''
					height={220}
					width={360}
					image={data.photoUrl || defaultImage}
					title=''
					style={{
						minWidth: 360,
						minHeight: 220,
						objectFit: 'cover',
					}}
				/>
			</Box>

			<CardContent className={classes.content}>
				<Box>
					<Typography variant='h6' component='h3'>
						{`Pond ${index + 1}`}
					</Typography>
					<Typography variant='caption' component='p' color='textSecondary'>
						{`DOC ${getDoc()} Days`}
					</Typography>
				</Box>
				<Box className={classes.grid}>
					<Box>
						<Typography variant='caption' component='p' color='textSecondary'>
							Animal Type
						</Typography>
						<Typography variant='subtitle2' component='p' color='textPrimary'>
							{data.animalType}
						</Typography>
						<Typography variant='caption' component='p' color='textSecondary'>
							No. of Acres
						</Typography>
						<Typography variant='subtitle2' component='p' color='textPrimary'>
							{data.numberOfAcres}
						</Typography>
					</Box>
					<Box>
						<Typography variant='caption' component='p' color='textSecondary'>
							Animal Variety
						</Typography>
						<Typography variant='subtitle2' component='p' color='textPrimary'>
							{data.animalVariety}
						</Typography>
						<Typography variant='caption' component='p' color='textSecondary'>
							Seed Count
						</Typography>
						<Typography variant='subtitle2' component='p' color='textPrimary'>
							{data.seedCount}
						</Typography>
					</Box>
				</Box>
				<Box>
					<Typography variant='caption' component='p' color='textSecondary'>
						Location
					</Typography>
					<Typography variant='subtitle2' component='p' color='textPrimary'>
						{data.address}
					</Typography>
				</Box>
			</CardContent>
			<PhotoDialog
				open={openDialog}
				setOpen={setOpenDialog}
				imageUrl={data.photoUrl || defaultImage}
			/>
		</Card>
	)
}
