import dashboard from '../assets/images/menu/dashboard.svg'
import farmer from '../assets/images/menu/farmer.png'
import feed from '../assets/images/menu/feed.png'
import price from '../assets/images/menu/price.svg'
import school from '../assets/images/menu/school.png'
import ticket from '../assets/images/menu/ticket.svg'
import scanner from '../assets/images/menu/scanner.svg'
import fieldVolunteers from '../assets/images/menu/field_volunteers.png'

export const menu = [
	{
		title: 'Dashboard',
		path: '/',
		icon: dashboard,
		children: [],
	},
	{
		title: 'Active Tickets',
		path: '/tickets/active',
		icon: ticket,
		children: [],
	},
	{
		title: 'Closed Tickets',
		path: '/tickets/closed',
		icon: ticket,
		children: [],
	},
	{
		title: 'Farmers',
		path: '/farmers',
		icon: farmer,
		children: [],
	},
]
