import { Box, Button, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'
import { http } from '../../api/http'

const useStyles = makeStyles((theme) => ({
	root: {
		background:
			'linear-gradient(108deg, rgba(0, 147, 233, 0.2) 0%, rgba(128, 208, 199, 0.2) 100%) 0% 0% no-repeat',
		padding: '10px 20px',
		borderRadius: '10px',
		marginBottom: '20px',
	},
	btn: {
		fontSize: '10px',
		padding: '5px',
		minWidth: '0',
		'& svg': {
			height: '1rem',
		},
	},
}))
const PondCard = ({ cardData, refresh }) => {
	const classes = useStyles()

	const handleDelete = () => {
		http
			.delete(`/prescription/${cardData.id}`)
			.then((res) => {
				refresh()
			})
			.catch((err) => console.log(err))
	}

	return (
		<Box className={classes.root}>
			<Box display='flex' justifyContent='space-between'>
				<Box>
					{cardData?.pondsDetails?.map((item, index) => (
						<Typography
							display='inline'
							key={item}
							variant='body1'
							color='primary'
						>
							{item.name}
						</Typography>
					))}
				</Box>
				<Box>
					<Button className={classes.btn} size='small' onClick={handleDelete}>
						<DeleteIcon />
					</Button>
				</Box>
			</Box>
			<Box>
				<Box color='primary'>
					<Typography display='inline' variant='body2'>
						{cardData?.medicine}
					</Typography>
					<Typography display='inline' variant='body2'>
						{cardData?.quantity}
					</Typography>
					<Typography display='inline' variant='body2'>
						{cardData?.units}
					</Typography>
				</Box>
				<Typography variant='body1' color='textPrimary'>
					{cardData?.medicineComposition}
				</Typography>
				<Typography variant='body2' color='textSecondary'>
					{cardData?.methodToUseMedicine}
				</Typography>
				<Typography variant='body2' color='textSecondary'>
					Number of Acres : {cardData?.numberOfAcres}
				</Typography>
			</Box>
		</Box>
	)
}

export default PondCard
