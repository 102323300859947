import { useContext } from 'react'
import { useState } from 'react'
import { createContext } from 'react'

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
	const [userRole, setUserRole] = useState(null)
	return (
		<AuthContext.Provider
			value={{
				userRole,
				setUserRole,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
