import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { Route, useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { http } from '../../api/http'
import { useState } from 'react'
import { useEffect } from 'react'
import StepperTimeline from './StepperTimeline'
import ActiveTicketCard from './ActiveTicketCard'
import Spinner from '../../shared/components/Spinner'
import { _isExpert, _isQC } from '../../shared/constants'
import NoResults from '../../shared/components/NoResults'
const Panel = () => {
	const history = useHistory()
	const farmerId = new URLSearchParams(history.location.search).get('farmerId')
	const [tickets, setTickets] = useState([])
	const [loading, setLoading] = useState(true)
	const { status } = useParams()
	const { path } = useRouteMatch()

	useEffect(() => {
		farmerId && getFarmerTickets()
		!farmerId && getData()
	}, [history.location.pathname, history.location.search])

	function getData() {
		if (_isExpert && !farmerId) {
			http
				.get(`/request-help/accepted-ticket?status=${status}`)
				.then((res) => {
					setTickets(res.data)
					setLoading(false)
				})
				.catch((err) => console.log(err))
		}
		if (_isQC && !farmerId) {
			http
				.get(
					`/request-help/accepted-ticket?status=Prescribed,Follow%20Up,QC%20Rejected,QC%20Approved`
				)
				.then((res) => {
					setTickets(res.data)
					setLoading(false)
				})
				.catch((err) => console.log(err))
		}
	}

	const getFarmerTickets = () => {
		if (farmerId) {
			http
				.get(
					`/request-help?status=Prescribed,Follow%20Up,QC%20Rejected,QC%20Approved&userId=${farmerId}`
				)
				.then((res) => {
					setTickets(res.data)
					setLoading(false)
				})
				.catch((err) => console.log(err))
		}
	}

	if (loading) return <Spinner />

	return (
		<Box display='flex'>
			<Box>
				<Box
					style={{
						overflowY: 'scroll',
						height: 'calc(100vh - 190px)',
						minWidth: '400px',
					}}
					pb={2}
					pr={2}
				>
					{tickets && (
						<>
							{tickets.length < 1 ? (
								<NoResults />
							) : (
								tickets.map((item) => (
									<ActiveTicketCard key={item.id} cardData={item} />
								))
							)}
						</>
					)}
				</Box>
			</Box>
			<Box flex='auto' maxWidth={650}>
				<Route path={`${path}/:id/timeline`} component={StepperTimeline} />
			</Box>
		</Box>
	)
}

export default Panel
