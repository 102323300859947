import React from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ActiveTickets from '../pages/active-tickets'
import Form from '../pages/prescription/Form'
import Pdf from '../pages/prescription/Pdf'
import TicketDetails from '../pages/active-tickets/TicketDetails'
import ClosedTickets from '../pages/closed-tickets'
import Dashboard from '../pages/dashboard'
import Farmers from '../pages/farmers'
import FarmerDetails from '../pages/farmers/FarmerDetails'
import ProtectedRoute from '../shared/components/ProtectedRoute'
import { _isExpert, _isQC } from '../shared/constants'
import ExpertProfile from './ExpertProfile'

function Routes() {
	return (
		<Switch>
			<ProtectedRoute path='/farmers/:id' component={FarmerDetails} />
			<ProtectedRoute path='/farmers' component={Farmers} />
			<ProtectedRoute path='/prescription/form/:id' component={Form} />
			<ProtectedRoute path='/prescription/pdf/:id' component={Pdf} />

			<ProtectedRoute path='/expert-profile' component={ExpertProfile} />

			<ProtectedRoute
				path='/tickets/active/details/:id'
				component={TicketDetails}
			/>

			<ProtectedRoute
				path='/tickets/active/:status'
				component={ActiveTickets}
			/>
			<ProtectedRoute
				path='/tickets/active'
				render={() => {
					if (_isExpert) {
						return <Redirect to='/tickets/active/Accepted' />
					}
					if (_isQC) {
						return <Redirect to='/tickets/active/Prescribed' />
					}
					return null
				}}
			/>
			<ProtectedRoute path='/tickets/closed' component={ClosedTickets} />
			<ProtectedRoute exact path='/' component={Dashboard} />
		</Switch>
	)
}

export default Routes
