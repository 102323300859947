import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { http } from '../../api/http'
import NewTicketCard from './NewTicketCard'
import Spinner from '../../shared/components/Spinner'
import { _isExpert } from '../../shared/constants'
import NoResults from '../../shared/components/NoResults'

function Dashboard() {
	const [stats, setStats] = useState([])
	const [tickets, setTickets] = useState([])
	const [loading, setLoading] = useState(false)
	useEffect(() => {
		getData()
	}, [])

	console.log({ _isExpert })
	console.log({ USER_ROLES: localStorage.getItem('USER_ROLE') })
	const getData = async () => {
		setLoading(true)
		let url = _isExpert
			? '/request-help?unassigned=true'
			: '/request-help?unassignedQCExpert=true&status=Prescribed'

		try {
			const [first, second] = await Promise.all([
				http.get(url),
				http.get('users/expert-stats'),
			])
			setTickets(first.data)
			setStats(second.data)
		} catch (err) {
			console.log(err)
		}

		setLoading(false)
	}

	if (loading) return <Spinner />

	return (
		<Grid container>
			<Grid item xs={8}>
				<Typography variant='h5'>New Tickets</Typography>
				<Box marginTop={2}>
					{tickets.length > 0 ? (
						tickets.map((item) => (
							<NewTicketCard key={item.id} cardData={item} />
						))
					) : (
						<Box maxWidth={400}>
							<NoResults />
						</Box>
					)}
				</Box>
			</Grid>
			{/* <Grid item xs={4}>
				<Typography variant='h5'>Stats</Typography>
				<Box
					display='flex'
					gridGap={20}
					flexWrap='wrap'
					flexDirection='column'
					mt={2}
				>
					{stats &&
						Object.entries(stats).map((item) => {
							return <StatsCard cardData={item} key={item[0]} />
						})}
				</Box>
			</Grid> */}
		</Grid>
	)
}

export default Dashboard

const StatsCard = ({ cardData }) => {
	const title = cardData[0]
	const count = cardData[1]

	return (
		<Paper variant='outlined'>
			<Box p={1}>
				<Typography variant='h6'>{title}</Typography>
				<Typography variant='body1'>{count}</Typography>
			</Box>
		</Paper>
	)
}
