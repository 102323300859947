import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import { Box } from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import {
	labelColor,
	QC_EXPERT_CHIP,
	_isExpert,
	_isQC,
} from '../../shared/constants'
import { useState } from 'react'
import { http } from '../../api/http'
import Spinner from '../../shared/components/Spinner'

const prescriptionAccess = [
	'Prescribed',
	'QC Approved',
	'QC Rejected',
	'Closed',
]

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginLeft: '20px',
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},
	link: {
		textDecoration: 'none',
		color: 'white',
	},
	editLink: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
}))

function getSteps() {
	return [
		'Farmer ticket Accepted ',
		'Contact the Farmer',
		'Prescription',
		'Under QC Review',
	]
}

export default function StepperTimeline() {
	const classes = useStyles()
	const params = useParams()
	const [loading, setLoading] = useState(false)
	const [ticketDetails, setTicketDetails] = useState()
	const [activeStep, setActiveStep] = useState(1)

	const steps = getSteps()

	useEffect(() => {
		getData()
	}, [params.id])

	const setStatus = (status) => {
		if (status) {
			switch (status) {
				case 'Accepted':
					setActiveStep(1)
					break

				case 'Prescribed':
					setActiveStep(2)
					break

				case 'QC Approved':
				case 'QC Rejected':
					setActiveStep(3)
					break

				default:
					setActiveStep(1)
					break
			}
		}
	}

	async function getData() {
		setLoading(true)
		try {
			const res = await http.get(`/request-help/${params.id}`)
			setTicketDetails(res.data)
			setStatus(res.data.status)
		} catch (error) {
			console.log(error)
		}
		setLoading(false)
	}

	const handleMakeCall = () => {
		http
			.post(`/request-help/expert/call/${ticketDetails?.id}`)
			.then(() => {})
			.catch((err) => console.log(err))
	}

	const renderSwitch = () => {
		switch (activeStep) {
			case 0:
			case 1:
				return (
					<Box display='flex' gridGap={20} alignItems='center'>
						<Button
							variant='contained'
							color='primary'
							onClick={handleMakeCall}
						>
							Make a Call
						</Button>
						<Button
							variant='outlined'
							color='primary'
							onClick={() => setActiveStep(2)}
						>
							Completed
						</Button>
					</Box>
				)
			case 2:
				if (prescriptionAccess.includes(ticketDetails.status)) {
					return (
						<Link
							className={classes.link}
							to={`/prescription/pdf/${params.id}`}
						>
							<Button variant='contained' color='primary'>
								View Prescription
							</Button>
						</Link>
					)
				}
				if (ticketDetails?.status === 'Accepted') {
					return (
						<Box display='flex' gridGap={20}>
							<Button
								variant='outlined'
								color='primary'
								onClick={() => setActiveStep(1)}
							>
								Back
							</Button>
							<Link
								className={classes.link}
								to={`/prescription/form/${params.id}`}
							>
								<Button variant='contained' color='primary'>
									Write a Prescription
								</Button>
							</Link>
						</Box>
					)
				}

			case 3:
				return (
					<Box>
						<Typography style={{ color: labelColor(ticketDetails?.status) }}>
							{ticketDetails?.status.slice(3)}
						</Typography>
						<Box display='flex' alignItems='center' gridGap={20}>
							<Box>
								<Typography variant='h6' color='textPrimary'></Typography>
								<Box display='flex' flexDirection='column' gridGap={2}>
									<Chip
										style={QC_EXPERT_CHIP}
										variant='default'
										label={`QC Expert: ${ticketDetails?.qcExpertName}`}
									/>
								</Box>
							</Box>
							<Box display='flex' flexDirection='column' gridGap={10}>
								{_isExpert && (
									<Link
										className={classes.editLink}
										to={`/prescription/form/${params.id}`}
									>
										Edit Prescription
									</Link>
								)}
								<ViewPrescriptionLink ticketId={params.id} />
							</Box>
						</Box>
					</Box>
				)
		}
	}

	if (loading) return <Spinner />

	return (
		<div className={classes.root}>
			<Box mt={1}>
				<Typography variant='h6' component='h2' color='textPrimary'>
					Issue Timeline
				</Typography>
			</Box>
			{ticketDetails && (
				<Stepper activeStep={activeStep} orientation='vertical'>
					{steps.map((label, index) => (
						<Step key={label}>
							<StepLabel>
								<Box>
									<span>{label}</span>
								</Box>
							</StepLabel>

							<StepContent>
								<div className={classes.actionsContainer}>
									<div>{index > 0 && <>{renderSwitch()}</>}</div>
								</div>
							</StepContent>
						</Step>
					))}
				</Stepper>
			)}
			{ticketDetails?.status === 'QC Approved' && (
				<Box ml={3}>
					<Typography variant='h6'>Follow Up</Typography>
					<Typography variant='body2'>
						{ticketDetails?.volunteerName}
					</Typography>
				</Box>
			)}
		</div>
	)
}

const ViewPrescriptionLink = ({ ticketId }) => {
	const classes = useStyles()
	const history = useHistory()
	const farmerId = new URLSearchParams(history.location.search).get('farmerId')
	return (
		<Link
			className={classes.editLink}
			to={{
				pathname: `/prescription/pdf/${ticketId}`,
				search: farmerId && `?farmerId=${farmerId}`,
			}}
		>
			View Prescription
		</Link>
	)
}
