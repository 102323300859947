import {
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { http } from '../../api/http'
import login from '../../assets/images/login.png'
import logo from '../../assets/images/logo.png'

// 1. change logo

function Login() {
	const { enqueueSnackbar } = useSnackbar()
	const history = useHistory()

	const [data, setData] = useState({
		mobile: '',
		password: '',
	})
	const [loading, setLoading] = useState(false)

	function handleSubmit(e) {
		e.preventDefault()
		setLoading(true)
		http
			.post('/auth/expert/login', data)
			.then((res) => {
				localStorage.clear()
				localStorage.setItem('access_token', `Bearer ${res.data.access_token}`)
				localStorage.setItem('USER_ROLE', JSON.stringify(res.data.role))
				window.location.href = '/'
			})
			.catch((err) => {
				if (!err?.response?.data) {
					enqueueSnackbar('Server Error', { variant: 'error' })
				} else {
					enqueueSnackbar(err.response.data.message, { variant: 'error' })
				}
				window.location.href = '/login'
			})
	}

	function handleOnchange(e) {
		setData({ ...data, [e.target.name]: e.target.value })
	}

	return (
		<Grid container>
			<Grid item xs={6}>
				<Box height='100vh'>
					<img
						src={login}
						style={{ height: '100%', width: '100%', objectFit: 'cover' }}
						alt=''
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box
					display='flex'
					minHeight='100vh'
					alignItems='center'
					justifyContent='center'
				>
					<Box maxWidth={500} width='100%' textAlign='center' py={6} px={4}>
						<form onSubmit={handleSubmit}>
							<Box>
								<img className='img_fluid' src={logo} alt='' />
							</Box>
							<Box mt={2}>
								<Typography variant='subtitle1'>
									Login to your account
								</Typography>
							</Box>
							<Box mt={3}>
								<TextField
									onChange={handleOnchange}
									margin='dense'
									variant='outlined'
									label='Mobile'
									name='mobile'
									required
									fullWidth
								/>
							</Box>
							<Box mt={3}>
								<TextField
									onChange={handleOnchange}
									margin='dense'
									variant='outlined'
									label='Password'
									name='password'
									type='password'
									required
									fullWidth
								/>
							</Box>
							<Box mt={3}>
								<Button
									fullWidth
									type='submit'
									margin='dense'
									variant='contained'
									color='primary'
									disabled={loading}
									endIcon={
										loading && <CircularProgress color='primary' size={20} />
									}
								>
									Login
								</Button>
							</Box>
						</form>
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Login
