import image from '../../assets/images/logo.png'

const container = {
	width: '100%',
	height: '400px',
	margin: '40px 0',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
}

const imageStyles = {
	height: '150px',
	width: '150px',
	filter: 'sepia(100%)',
	opacity: 0.6,
}

const NoResults = () => {
	return (
		<div height='100%' width='100%' style={container}>
			<h2 style={{ opacity: 0.8 }}>No Tickets</h2>
			<img src={image} alt='no-image' style={imageStyles} />
		</div>
	)
}

export default NoResults
