import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { Link, useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect } from 'react'
import { http } from '../../api/http'
import { useState } from 'react'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import PondCard from './PondCard'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: '1020px',
	},
	textField: {
		width: '100%',
		'& input[type="text"]:disabled': {
			color: theme.palette.text.primary,
		},
		marginBottom: '30px',
		'& textarea:disabled': {
			color: theme.palette.text.primary,
		},
	},
}))

const CustomTextField = ({ ...restProps }) => {
	const classes = useStyles()

	return (
		<TextField
			className={classes.textField}
			variant='outlined'
			required
			size='small'
			{...restProps}
		/>
	)
}

const Form = () => {
	const { enqueueSnackbar } = useSnackbar()
	const [medicines, setMedicines] = useState([])
	const [units, setUnits] = useState([])
	const [compositions, setCompositions] = useState([])
	const [data, setData] = useState()
	const [loading, setLoading] = useState(false)
	const [prescriptions, setPrescriptions] = useState([])
	const [formData, setFormData] = useState({
		date: moment(new Date()).format('YYYY-MM-DD'),
		medicine: '',
		units: '',
		medicineComposition: '',
		methodToUseMedicine: '',
		issueDescription: '',
		quantity: '',
	})
	const [totalAceres, setTotalAceres] = useState(0)

	const [pondIds, setPondIds] = useState([])
	const [selectedPonds, setSelectedPonds] = useState([])

	const { id: ticketId } = useParams()

	const history = useHistory()
	const classes = useStyles()

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	async function getData() {
		const [first, second, third, fourth, fifth] = await Promise.all([
			http.get(`/prescription/medicine`),
			http.get(`/prescription/unit`),
			http.get(`/prescription/composition`),
			http.get(`/request-help/${ticketId}`),
			http.get(`prescription/request-help/${ticketId}`),
		])

		setMedicines(first.data)
		setUnits(second.data)
		setCompositions(third.data)
		setData(fourth.data)
		setPrescriptions(fifth.data)
		setPondIds(
			fourth.data.pondDetails.map((item) => ({
				id: item.id,
				address: item.address,
				doc: item.doc,
				name: item.name,
			}))
		)
		setFormData((prev) => ({
			...prev,
			issueDescription: fourth.data.issueDescription,
		}))
		setTotalAceres(
			fourth.data.pondDetails
				.map((item) => +item.numberOfAcres)
				.reduce((prev, curr) => prev + curr, 0)
		)
		setLoading(false)
	}

	const handleNewPrescription = (e) => {
		e.preventDefault()

		if (selectedPonds.length > 0) {
			http
				.post(`/prescription/${ticketId}`, {
					...formData,
					ponds: selectedPonds,
				})
				.then((res) => {
					getData()
					enqueueSnackbar('Prescription Added', { variant: 'success' })
					setFormData({
						date: moment(new Date()).format('YYYY-MM-DD'),
						medicine: '',
						units: '',
						medicineComposition: '',
						methodToUseMedicine: '',
						issueDescription: '',
						quantity: '',
					})
					setSelectedPonds([])
				})
				.catch((err) => console.log(err))
		} else {
			enqueueSnackbar('Select Tanks', { variant: 'error' })
		}
	}

	const handleSelectedPonds = (pondId) => {
		if (pondIds == null) return
		setSelectedPonds((prev) => {
			if (prev.includes(pondId)) {
				return prev.filter((item) => item !== pondId)
			} else {
				return [...prev, pondId]
			}
		})
	}

	const handleDateChange = (e) => {
		setFormData((prev) => ({
			...prev,
			date: e.target.value,
		}))
	}

	if (loading) return <CircularProgress />

	return (
		<Box className={classes.root}>
			<Box display='flex' alignItems='center' gridGap={20}>
				<Link style={{ lineHeight: 0 }} to='/tickets/active'>
					<KeyboardBackspaceIcon />
				</Link>
				<Typography>Write Prescription</Typography>
			</Box>
			<Box mt={2} className={classes.formTop}>
				<Grid container spacing={3}>
					<Grid item xs={6} spacing={3}>
						<CustomTextField
							defaultValue={data?.name}
							disabled
							label='Farmer Name'
						/>
						<CustomTextField
							defaultValue={data?.address || '-'}
							disabled
							label='Village / Area'
						/>
						<CustomTextField
							defaultValue={data?.ticketId || '-'}
							disabled
							label='Reg. Number'
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomTextField
							defaultValue={data?.mobile}
							disabled
							label='Phone Number'
						/>

						<CustomTextField
							variant='outlined'
							size='small'
							defaultValue={moment(Date.now()).format('YYYY-MM-DD')}
							required
							type='date'
							value={formData?.date}
							onChange={handleDateChange}
						/>
						<CustomTextField
							disabled
							value={totalAceres}
							label='Total No. of Aceres'
						/>
					</Grid>
				</Grid>
				<Box>
					<CustomTextField
						label='Issue Description'
						disabled
						minRows={3}
						multiline
						defaultValue={data?.issuesDetails
							.map((item) => item.name)
							.join(' ')}
					/>
				</Box>
			</Box>
			{prescriptions.length > 0 && (
				<Box>
					{prescriptions.map((item) => (
						<PondCard key={item.id} refresh={getData} cardData={item} />
					))}
				</Box>
			)}

			<form onSubmit={handleNewPrescription}>
				<Box>
					<Box mb={2}>
						<Typography variant='h6' component='h3' color='textPrimary'>
							{prescriptions.length > 0 ? 'New Prescription' : 'Prescription'}
						</Typography>
					</Box>
				</Box>
				<Box mb={1}>
					<Typography variant='body2' color='textSecondary' component='h4'>
						Select multiple Tanks/Ponds
					</Typography>
					{pondIds.length > 0 && (
						<Box my={1} mb={3}>
							<FormControl component='fieldset' required>
								<FormGroup row>
									{pondIds.map((item, index) => {
										return (
											<FormControlLabel
												key={item.id}
												value={item.id}
												control={
													<Checkbox
														color='primary'
														checked={selectedPonds.includes(item.id)}
														onChange={() => handleSelectedPonds(item.id)}
													/>
												}
												label={<Pond index={index + 1 + 1} item={item} />}
											/>
										)
									})}
								</FormGroup>
							</FormControl>
						</Box>
					)}
				</Box>
				<Box>
					<Box display='flex' gridGap={30}>
						<Box flex='2'>
							<Autocomplete
								options={medicines}
								getOptionLabel={(option) => option}
								value={formData.medicine}
								size='small'
								onChange={(e, newValue) =>
									setFormData((prev) => ({
										...prev,
										medicine: newValue,
									}))
								}
								renderInput={(params) => (
									<CustomTextField {...params} label='Select Medicine' />
								)}
							/>
						</Box>
						<Box flex='1'>
							<CustomTextField
								type='number'
								label='Quantity'
								value={formData.quantity}
								size='small'
								onChange={(e) =>
									setFormData((prev) => ({
										...prev,
										quantity: e.target.value,
									}))
								}
							/>
						</Box>
						<Box flex='1'>
							<Autocomplete
								options={units}
								size='small'
								value={formData.units}
								getOptionLabel={(option) => option}
								onChange={(e, newValue) =>
									setFormData((prev) => ({
										...prev,
										units: newValue,
									}))
								}
								renderInput={(params) => (
									<CustomTextField {...params} label='Units' />
								)}
							/>
						</Box>
					</Box>
					<Box>
						<Autocomplete
							options={compositions}
							getOptionLabel={(option) => option}
							size='small'
							value={formData.medicineComposition}
							onChange={(e, newValue) =>
								setFormData((prev) => ({
									...prev,
									medicineComposition: newValue,
								}))
							}
							renderInput={(params) => (
								<CustomTextField {...params} label='How to use Medicine' />
							)}
						/>
					</Box>
					{/* <CustomTextField
						label='How to use the Medicine'
						rows={4}
						multiline
						value={formData.methodToUseMedicine}
						onChange={(e) =>
							setFormData((prev) => ({
								...prev,
								methodToUseMedicine: e.target.value,
							}))
						}
					/> */}
				</Box>
				<Box display='flex' justifyContent='center' gridGap={30} mb={3}>
					<Button variant='outlined' type='submit' color='primary'>
						Add New Medicine
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={(e) => {
							e.stopPropagation()
							e.preventDefault()
							history.push(`/prescription/pdf/${ticketId}`)
						}}
					>
						Generate Prescription
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default Form

const Pond = ({ index, item }) => {
	const getDoc = () => {
		const startDate = moment(item?.doc)
		const endDate = moment()
		return endDate.diff(startDate, 'days')
	}

	return (
		<Box>
			<Typography variant='body1'>{item.name}</Typography>
			<Box display='flex' gridGap={10}>
				<Typography
					variant='body2'
					color='textSecondary'
				>{`DOC ${getDoc()} Days | `}</Typography>
				<Typography variant='body2' color='textSecondary'>
					{item.address}
				</Typography>
			</Box>
		</Box>
	)
}
