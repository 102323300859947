import { Box, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { http } from '../../api/http'
import ClosedTicketCard from './ClosedTicketCard'
import Spinner from '../../shared/components/Spinner'
import NoResults from '../../shared/components/NoResults'

const ClosedTickets = () => {
	const [tickets, setTickets] = useState([])
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	const getData = () => {
		http
			.get('/request-help/accepted-ticket?status=Closed')
			.then((res) => setTickets(res.data))
			.catch((err) => console.log(err))
		setLoading(false)
	}
	if (loading) return <Spinner />

	return (
		<div>
			<Typography variant='h5' component='h1'>
				Completed / Closed Tickets
			</Typography>
			<Box mt={2}>
				{tickets.length < 1 ? (
					<Box maxWidth={400}>
						<NoResults />
					</Box>
				) : (
					tickets.map((item) => (
						<ClosedTicketCard cardData={item} key={item.id} />
					))
				)}
			</Box>
		</div>
	)
}

export default ClosedTickets
