import { ThemeProvider } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import './App.css'
import Layout from './Layout'
import Login from './pages/Login'
import theme from './styles/theme'

function App() {
	return (
		<SnackbarProvider
			maxSnack={3}
			autoHideDuration={3000}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Switch>
						<Route path='/login'>
							<Login />
						</Route>
						<Route path='/'>
							{localStorage.getItem('access_token') ? (
								<Layout />
							) : (
								<Redirect to='/login' />
							)}
						</Route>
					</Switch>
				</BrowserRouter>
			</ThemeProvider>
		</SnackbarProvider>
	)
}

export default App
