import { Typography, Box, Divider } from '@material-ui/core'
import { useState } from 'react'
import { Link, NavLink, Route, useHistory } from 'react-router-dom'
import Panel from './Panel'
import { makeStyles } from '@material-ui/core/styles'
import { _isExpert } from '../../shared/constants'
import { useEffect } from 'react'
import { http } from '../../api/http'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const useStyles = makeStyles((theme) => ({
	tabsBar: {
		display: 'flex',
		gap: '40px',
	},
	tab: {
		textDecoration: 'none',
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.secondary,
	},
	activeTab: {
		color: theme.palette.primary.main,
		'&::after': {
			content: '""',
			display: 'block',
			padding: '2px 3px',
			height: '5px',
			width: '6ch',
			background: theme.palette.primary.main,
			borderRadius: '3px',
			marginTop: '5px',
		},
	},
}))

const ActiveTickets = () => {
	const classes = useStyles()
	const history = useHistory()
	const farmerId = new URLSearchParams(history.location.search).get('farmerId')

	const [data, setData] = useState({
		Accepted: 0,
		Prescribed: 0,
		'QC Approved': 0,
		'QC Rejected': 0,
		'Follow Up': 0,
		Closed: 0,
	})
	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		http
			.get('/users/expert-stats')
			.then((res) => setData(res.data))
			.catch((err) => console.log(err))
	}

	return (
		<>
			{farmerId ? (
				<Box display='flex' alignItems='center' gridGap={20}>
					<Link onClick={() => history.goBack()}>
						<KeyboardBackspaceIcon />
					</Link>
					<Typography variant='h5' component='h1'>
						Farmer's Tickets
					</Typography>
				</Box>
			) : (
				<Typography variant='h5' component='h1'>
					Active Tickets
				</Typography>
			)}
			<Box marginTop={2}>
				{_isExpert && !farmerId && (
					<>
						<Box className={classes.tabsBar}>
							<NavLink
								activeClassName={classes.activeTab}
								className={classes.tab}
								to={{
									pathname: '/tickets/active/Accepted',
									// search: farmerId && `?farmerId=${farmerId}`,
								}}
							>
								<span>{`In Process `}</span>
							</NavLink>
							<NavLink
								activeClassName={classes.activeTab}
								className={classes.tab}
								to={{
									pathname: '/tickets/active/Prescribed,Follow%20Up',
									// search: farmerId && `?farmerId=${farmerId}`,
								}}
							>
								<span>{`Prescribed `}</span>
							</NavLink>
							<NavLink
								activeClassName={classes.activeTab}
								className={classes.tab}
								to={{
									pathname: '/tickets/active/QC%20Rejected',
									// search: farmerId && `?farmerId=${farmerId}`,
								}}
							>
								<span>{`QC Rejected `}</span>
							</NavLink>
						</Box>
						<Divider />
					</>
				)}
				<Route path='/tickets/active/:status' component={Panel} />
			</Box>
		</>
	)
}

export default ActiveTickets
