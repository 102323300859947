import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@material-ui/core'
import {useEffect, useState} from 'react'
import {Link, useHistory, useParams} from 'react-router-dom'
import {http} from '../../api/http'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {jsPDF} from 'jspdf'
import headerLogo from '../../assets/images/Royye_pdf.png'
import * as htmlToImage from 'html-to-image'
import {useSnackbar} from 'notistack'
import moment from 'moment'
import {_isExpert, _isQC} from '../../shared/constants'
import {green, red} from '@material-ui/core/colors'

const Pdf = () => {
	const {enqueueSnackbar} = useSnackbar()
	const history = useHistory()
	const {id: ticketId} = useParams()
	const [ticketDetails, setTicketDetails] = useState()
	const [prescriptions, setPrescriptions] = useState()
	const [expertDetails, setExpertDetails] = useState(null)
	const [loading, setLoading] = useState(false)
	const [posting, setPosting] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)
	const [rejectReason, setRejectReason] = useState('')

	const farmerId = new URLSearchParams(history.location.search).get('farmerId')

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		setLoading(true)
		const [first, second] = await Promise.all([
			http.get(`/request-help/${ticketId}`),
			http.get(`prescription/request-help/${ticketId}`),
		])
		setTicketDetails(first.data)
		setPrescriptions(second.data)
		const res = await http.get(`users/expert/profile/${first.data.expertId}`)
		setLoading(false)
		setExpertDetails(res.data)
	}

	const generatePdf = async (e) => {
		e.preventDefault()
		setPosting(true)

		const pdfImage1 = document.getElementById('pdf_page1')
		const pdfImage2 = document.getElementById('pdf_page2')
		const pdfImageNew = document.getElementById('pdf_page_new')

		let pdf = new jsPDF('p', 'mm', 'a4')

		const dataUrl1 = await htmlToImage.toJpeg(pdfImage1, {quality: 1})
		pdf.addImage(dataUrl1, 'JPG', 0, 0)
		pdf.addPage()
		if (pdfImageNew) {
			const dataUrlNew = await htmlToImage.toJpeg(pdfImageNew, {quality: 1})
			pdf.addImage(dataUrlNew, 'JPG', 0, 0)
			pdf.addPage()
		}

		const dataUrl2 = await htmlToImage.toJpeg(pdfImage2, {quality: 1})
		pdf.addImage(dataUrl2, 'JPG', 0, 0)

		let generatedFile = pdf.output('dataurl')
		let file = dataURLtoFile(generatedFile, 'doc.pdf')
		const data = new FormData()
		data.append('file', file)

		// pdf.save()

		try {
			await http.post(
				`/prescription/post-prescription/${ticketDetails?.id}`,
				data
			)
			enqueueSnackbar('Prescription Generated', {variant: 'success'})
			getData()
			setPosting(false)
		} catch (error) {
			if (error)
				enqueueSnackbar('Error generating Prescription', {variant: 'error'})
			setPosting(false)
		}
	}
	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(',')
		const mime = arr[0].match(/:(.*?);/)[1]
		const bstr = atob(arr[1])
		let n = bstr.length
		const u8arr = new Uint8Array(n)
		while (n--) u8arr[n] = bstr.charCodeAt(n)
		return new File([u8arr], filename, {type: mime})
	}

	const handleAcceptPrescription = () => {
		http
			.post(`/request-help/qc-expert/${ticketDetails?.id}/approved`)
			.then(() => {
				history.push('/tickets/active')
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const handleRejectPrescription = (e) => {
		e.preventDefault()
		http
			.post(`/request-help/qc-expert/${ticketDetails?.id}/rejected`, {
				rejectionReason: rejectReason,
			})
			.then(() => {
				history.push('/tickets/active')
			})
			.catch((err) => {
				console.error(err)
			})
	}

	const getGoBacklink = () => {
		if (farmerId) {
			return `/farmers/${farmerId}`
		}
		if (_isExpert) {
			if (ticketDetails?.status !== 'Accepted') {
				return `/tickets/active`
			} else {
				return `/prescription/form/${ticketId}`
			}
		}
		if (_isQC) {
			return `/tickets/active`
		}
	}

	if (loading) return <CircularProgress />
	return (
		<div>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				mb={2}
			>
				<Box display='flex' alignItems='center' gridGap={20}>
					<Link style={{lineHeight: 0}} to={getGoBacklink()}>
						<KeyboardBackspaceIcon />
					</Link>
					<Typography display='inline'>
						{_isExpert ? `Write Prescription` : `Prescription Preview`}
					</Typography>
				</Box>
				<Box>
					{_isExpert && ticketDetails?.status !== 'Prescribed' && (
						<Button
							variant='contained'
							color='primary'
							disabled={posting}
							onClick={generatePdf}
							endIcon={posting ? <CircularProgress size='small' /> : ''}
						>
							Post Prescription
						</Button>
					)}
					{_isQC && (
						<Box display='flex' alignItems='center' gridGap={30}>
							<Button
								style={{background: red[700], color: 'white'}}
								variant='contained'
								size='large'
								onClick={() => setOpenDialog(true)}
							>
								Reject
							</Button>
							<Button
								style={{background: green[700], color: 'white'}}
								color='success'
								variant='contained'
								size='large'
								onClick={handleAcceptPrescription}
							>
								Approve
							</Button>
						</Box>
					)}
				</Box>
			</Box>
			<div className='' id='pdf'>
				<div className='' id='pdf_page1'>
					<PdfTemplate
						ticketDetails={ticketDetails}
						expertDetails={expertDetails}
					>
						<section id='pdf_page1'>
							<div className='presc'>
								<h3 className='presc__heading'>ఔషధ పత్రం</h3>
								<div className='presc__box'>
									<div className='presc__box-row'>
										<div className='farmer-item'>
											<span className='farmer-item__heading'>సమస్య : </span>
											<span>
												{ticketDetails?.issuesDetails.map(
													(item) => `${item.name}`
												)}
											</span>
										</div>
									</div>
									<ul className='presc-list' id='presc-list'>
										{prescriptions?.slice(0, 6).map((item) => {
											return (
												<li key={item.id} className='presc-item'>
													<span>{item.medicine + ' - '}</span>
													<span>{item.quantity}</span>
													<span>{item.units + ','}</span>
													<span>{item.medicineComposition}</span>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</section>
					</PdfTemplate>
				</div>
				{prescriptions?.length > 6 && (
					<div className='' id='pdf_page_new'>
						<PdfTemplate
							ticketDetails={ticketDetails}
							expertDetails={expertDetails}
						>
							<section id='pdf_page1'>
								<div className='presc'>
									<h3 className='presc__heading'>ఔషధ పత్రం</h3>
									<div className='presc__box'>
										<div className='presc__box-row'>
											<div className='farmer-item'>
												<span className='farmer-item__heading'>సమస్య : </span>
												<span>
													{ticketDetails?.issuesDetails.map(
														(item) => `${item.name}`
													)}
												</span>
											</div>
										</div>
										<ul className='presc-list' id='presc-list'>
											{prescriptions?.slice(6).map((item) => {
												return (
													<li key={item.id} className='presc-item'>
														<span>{item.medicine + ' - '}</span>
														<span>{item.quantity}</span>
														<span>{item.units + ','}</span>
														<span>{item.medicineComposition}</span>
													</li>
												)
											})}
										</ul>
									</div>
								</div>
							</section>
						</PdfTemplate>
					</div>
				)}
				<div className='' id='pdf_page2'>
					<PdfTemplate
						ticketDetails={ticketDetails}
						expertDetails={expertDetails}
					>
						<div className='presc__box'>
							<h3>అధిక దిగుబడులు రావడానికి పాటించవలసిన సూచనలు & మందులు:-</h3>
							<ol>
								<li>
									7 రోజులకు ఒక్కసారి తప్పనిసరిగా వాటర్ టెస్టింగ్ చెయ్యించాలి.
								</li>
								<li>
									ప్రతి అష్టమి అమావాస్య పౌర్ణమి రెండు రోజులు ముందు హార్డన్స్
									(కఠినత ) అధికంగా ఉంటే ZN మ్యాట్రిక్ (EDTA)- 2-5
									కిలోలు/ఎకరానికి నీటిలో కలపాలి.
								</li>
								<li>
									ప్రతి అష్టమి అమావాస్య పౌర్ణమి ఒక్క రోజు ముందు ఆర్గానిక్
									కాన్సాన్ ట్రేటిడ్ మినరల్స్ అయినా "గోల్డ్ మిన్" ని 10 కిలోలు/
									ఎకరానికి నీటిలో కలపాలి.
								</li>
								<li>
									చెరువు పరిస్థితిని బట్టి 7 రోజులకి ఒకసారి ప్రోబైయటిక్ ఐన
									"పయోనీర్" నీ 250 గ్రాములు/ ఎకరానికి, 3 కిలోలు బెల్లంతో
									కలుపుకుని, 4 గంటలు నానబెట్టి ఉదయం 9 గంటల లోపు పాండ్లో
									చల్లుకోవాలి.{' '}
								</li>
								<li>
									పిల్ల వదిలిన 25 రోజుల నుండి రొయ్యల్లో ఫీడ్ సామర్థ్యం
									పెరగడానికి, అలాగే వైట్ గట్ రాకుండా అడ్వాన్స్ గా సాయంత్రం పూట
									ఫీడ్ తో పాటు " ఏంజోలాక్" ని ఫీడ్ కీ 10 గ్రాములు చొప్పున
									ఇచ్చుకోవాలి
								</li>
								<li>
									గమనిక : పై సూచనలు పాటిస్తూ, మా ఎక్స్పర్ట్ సూచించిన మందులు
									వాడితే మన రొయ్యరాజు రైతులు నుంచి మంచి దిగుబడులు సాధించడం
									జరుగుతుంది.{' '}
								</li>
							</ol>
						</div>
					</PdfTemplate>
				</div>
			</div>

			<div>
				<Dialog
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					maxWidth='md'
				>
					<Box width={600}>
						<Box display='flex' justifyContent='space-between'>
							<DialogTitle id='form-dialog-title'>
								Reason For Rejection
							</DialogTitle>
							<DialogActions>
								<Button onClick={() => setOpenDialog(false)}>Cancel</Button>
							</DialogActions>
						</Box>
						<DialogContent>
							<form onSubmit={handleRejectPrescription}>
								<TextField
									variant='outlined'
									label='Enter Reason for Rejection'
									multiline
									value={rejectReason}
									onChange={({target}) => setRejectReason(target.value)}
									minRows={4}
									fullWidth
								/>
								<Box textAlign='center' my={2}>
									<Button variant='contained' color='primary' type='submit'>
										Submit
									</Button>
								</Box>
							</form>
						</DialogContent>
					</Box>
				</Dialog>
			</div>
		</div>
	)
}

export default Pdf

const PdfTemplate = ({ticketDetails, expertDetails, children}) => {
	return (
		<section className='pdf'>
			<header>
				<img className='pdf-header__logo' src={headerLogo} alt='' />
				<div className='pdf-header__text'>
					<div>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							{ticketDetails?.expertName}
						</span>
						<span>
							{expertDetails?.qualification &&
								`(${expertDetails?.qualification})`}
						</span>
						<span>{expertDetails?.designation || ''}</span>
						{expertDetails?.experience && (
							<span>
								<span style={{fontWeight: 'bold'}}>Experience: </span>
								{expertDetails?.experience || ''}
							</span>
						)}
					</div>
					<p className='pdf-header__text-tollfree'>
						ఉచిత టోల్ ఫ్రీ :- 1800 12399 3333
					</p>
				</div>
			</header>

			<main>
				<section className='farmer'>
					<div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Farmer Name : </span>
							<span>{ticketDetails?.name}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Village / Area : </span>
							<span>{ticketDetails?.address}</span>
						</div>
					</div>
					<div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Farmer Mobile Number :{' '}
							</span>
							<span>{ticketDetails?.mobile}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Issue Ticket Number :
							</span>
							<span>{ticketDetails?.ticketId}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Farmer Total Acreage :{' '}
							</span>
							<span>
								{ticketDetails?.pondDetails
									.map((item) => +item.numberOfAcres)
									.reduce((prev, curr) => prev + curr, 0)}{' '}
								{` Acres`}
							</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Date : </span>
							<span>{moment(Date.now()).format('DD-MM-YYYY')}</span>
						</div>
					</div>
				</section>
				{children}
			</main>
			<footer>
				<img src='/footer_image.png' alt='' />
			</footer>
		</section>
	)
}
