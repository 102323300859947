import {
	AppBar,
	Box,
	Button,
	IconButton,
	Menu,
	MenuItem,
} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { AccountCircle } from '@material-ui/icons'
import React, { useState } from 'react'
import { Link, useHistory, withRouter } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import matrixLogo from '../assets/images/matrix_logo.png'
import { menu } from './menu'
import Routes from './Routes'
import { _isExpert } from '../shared/constants'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		boxShadow: '0px 0px 15px rgb(0 0 0 / 20%)',
		background: theme.palette.primary.main,
	},
	drawerContainer: {
		overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	menu: {
		width: drawerWidth,
	},
}))

function Layout() {
	const classes = useStyles()
	const history = useHistory()
	let pathname = history.location.pathname

	function handleLogout() {
		let confirm = window.confirm('Are you sure you want to logout?')
		if (!confirm) return
		localStorage.clear()
		window.location.href = '/'
	}
	function handleMenu() {
		history.push(`/expert-profile`)
	}
	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar color='inherit' position='fixed' className={classes.appBar}>
				<Toolbar style={{ justifyContent: 'space-between' }}>
					<Typography variant='h6' noWrap>
						<Box display='flex' gridGap={20} alignItems='center'>
							<img width={50} src={logo} alt='' />
							<img width={120} src={matrixLogo} alt='' />
						</Box>
					</Typography>

					<Box display='flex' gridGap={20}>
						<Box>
							{_isExpert && (
								<IconButton onClick={handleMenu} color='inherit'>
									<AccountCircle />
								</IconButton>
							)}
							{/* <Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={open}
								onClose={handleClose}
							>
								<MenuItem onClick={handleClose}>Profile</MenuItem>
								<MenuItem onClick={handleClose}>My account</MenuItem>
							</Menu> */}
						</Box>
						<Button onClick={handleLogout}>Logout</Button>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				className={classes.drawer}
				variant='permanent'
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<Toolbar />
				<div className={classes.drawerContainer}>
					<List>
						{menu.map((item) => (
							<Link
								key={item.path}
								to={`${item.path}`}
								style={{
									color: 'white',
								}}
							>
								<ListItem
									selected={pathname.includes(item.path)}
									button
									key={`${item.path}`}
								>
									<ListItemIcon>
										<img width={16} src={item.icon} alt='' />
									</ListItemIcon>
									<ListItemText primary={`${item.title}`} />
								</ListItem>
							</Link>
						))}
					</List>
				</div>
			</Drawer>
			<main className={classes.content}>
				<Toolbar />
				<Routes />
			</main>
		</div>
	)
}

export default withRouter(Layout)
