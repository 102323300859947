import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	Link as MatLink,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { http } from '../../api/http'
import { _isExpert, _isQC } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 440,
		marginBottom: 20,
	},
	cardFooter: {
		width: '100%',
		display: 'flex',
		gap: '10px',
	},
	btnAccept: {
		width: '50%',
		textAlign: 'center',
		padding: '10px 0',
		marginLeft: 'auto',
	},
	btnReject: {
		width: '50%',
		textAlign: 'center',
		background: theme.palette.primary.white,
		padding: '10px 0',
		color: theme.palette.common.main,
	},
	link: {
		cursor: 'pointer',
		fontSize: '1rem',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}))

const NewTicketCard = ({ cardData }) => {
	const classes = useStyles()
	const history = useHistory()
	const {
		id,
		name,
		createdAt,
		priority,
		ticketId,
		expertId,
		expertName,
		callNow,
		timeSlot,
		userId,
		slotDate,
	} = cardData

	const handleAcceptTicket = () => {
		if (_isExpert) {
			http
				.post(`/request-help/${id}`)
				.then(() => {
					history.push(`/tickets/active/Accepted/${id}/timeline`)
				})
				.catch((err) => console.log(err))
		}
		if (_isQC) {
			http
				.post(`/request-help/qc-expert/${id}`)
				.then(() => {
					history.push(`/tickets/active/Prescribed/${id}/timeline`)
				})
				.catch((err) => console.log(err))
		}
	}

	return (
		<Card className={classes.root} variant='elevation'>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Box padding={1} marginLeft={1}>
					<Typography variant='caption' component='h4' color='textSecondary'>
						Issue Id
					</Typography>
					<Typography variant='h6' component='p' color='textPrimary'>
						{ticketId}
					</Typography>
				</Box>
				<Typography
					color='primary'
					variant='body2'
					style={{ marginRight: '20px' }}
				>
					New
				</Typography>
			</Box>
			<Divider />
			<CardContent>
				<Box display='flex' justifyContent='space-between' marginBottom={2}>
					<Box>
						<Typography variant='caption' component='h4' color='textSecondary'>
							Farmer Name
						</Typography>
						<MatLink
							className={classes.link}
							onClick={(e) => {
								e.preventDefault()
								e.stopPropagation()
								history.push(`/farmers/${userId}`)
							}}
						>
							{name}
						</MatLink>
					</Box>
					<Box>
						<Typography variant='caption' component='h4' color='textSecondary'>
							Requested Date
						</Typography>
						<Typography variant='body1' component='p' color='textPrimary'>
							{`${moment(createdAt).format('Do MMMM YYYY')} at ${moment(
								createdAt
							).format('LT')}`}
						</Typography>
					</Box>
				</Box>
				{expertId && (
					<Box>
						<Typography variant='caption' component='h4' color='textSecondary'>
							Assigned Expert
						</Typography>
						<Box display='flex' alignItems='center' gridGap={20}>
							<Box>
								<Typography variant='body1'>{expertName}</Typography>
							</Box>
						</Box>
					</Box>
				)}
				{!expertId && (
					<Box display='flex' gridGap={50}>
						<Box>
							<Typography
								variant='caption'
								component='h4'
								color='textSecondary'
							>
								Priority
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{priority}
							</Typography>
							<Typography>{callNow ? 'Call Now' : 'Call Later'}</Typography>
						</Box>
						{!callNow && (
							<>
								<Box>
									<Typography
										variant='caption'
										component='h4'
										color='textSecondary'
									>
										Slot Date
									</Typography>
									<Typography variant='body1' component='p' color='textPrimary'>
										{slotDate}
									</Typography>
								</Box>
								<Box>
									<Typography
										variant='caption'
										component='h4'
										color='textSecondary'
									>
										Slot Time
									</Typography>
									<Typography variant='body1' component='p' color='textPrimary'>
										{timeSlot}
									</Typography>
								</Box>
							</>
						)}
					</Box>
				)}
			</CardContent>
			<Divider />
			<>
				<Box className={classes.cardFooter}>
					<Button
						className={classes.btnAccept}
						variant='contained'
						color='primary'
						onClick={handleAcceptTicket}
					>
						Accept
					</Button>
				</Box>
			</>
		</Card>
	)
}

export default NewTicketCard
