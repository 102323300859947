import {
	Box,
	Card,
	Divider,
	Typography,
	Chip,
	Link as MatLink,
} from '@material-ui/core'
import moment from 'moment'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { labelColor } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minWidth: '360px',
		marginRight: '30px',
		marginTop: '20px',
		padding: 15,
		marginLeft: '5px',
	},
	viewBtn: {
		padding: '1ch 2ch',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '3ch',
	},
	link: {
		cursor: 'pointer',
		fontSize: '1rem',
		'&:hover': {
			color: theme.palette.primary.main,
		},
	},
}))

const ActiveTicketCard = ({ cardData }) => {
	const {
		id,
		name,
		createdAt,
		status,
		ticketId,
		userId,
		callNow,
		timeSlot,
		slotDate,
		priority,
	} = cardData
	const classes = useStyles()
	const router = useRouteMatch()
	const history = useHistory()

	const selectedCard = window.location.pathname.split('/')[4]

	const farmerId = new URLSearchParams(history.location.search).get('farmerId')
	return (
		<Card
			variant='outlined'
			className={classes.root}
			style={{
				boxShadow: `${selectedCard == id ? '0 0  2px 1px  #BF344E' : ''}`,
				transitionDuration: '200ms',
				transitionTimingFunction: 'ease',
			}}
		>
			<Link
				to={{
					pathname: `${router.url}/${id}/timeline`,
					search: farmerId && `?farmerId=${farmerId}`,
				}}
			>
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					mb={1}
				>
					<Box>
						<Typography variant='caption' component='h4' color='textSecondary'>
							Issue Id
						</Typography>
						<Typography variant='body1' component='p' color='textPrimary'>
							{ticketId}
						</Typography>
					</Box>
					<Chip
						size='small'
						label={status}
						variant='default'
						style={{
							color: 'white',
							background: labelColor(status),
							paddingTop: '2px',
						}}
					/>
				</Box>
				<Divider />
				<Box my={2}>
					<Box display='flex' justifyContent='space-between' gridGap={20}>
						<Box>
							<Typography
								variant='caption'
								component='h4'
								color='textSecondary'
								underline={true}
							>
								Farmer Name
							</Typography>
							<MatLink
								className={classes.link}
								onClick={(e) => {
									e.preventDefault()
									e.stopPropagation()
									history.push(`/farmers/${userId}`)
								}}
							>
								{name}
							</MatLink>
						</Box>
						<Box>
							<Typography
								variant='caption'
								component='h4'
								color='textSecondary'
							>
								Requested Date
							</Typography>
							<Typography variant='body2' component='p' color='textPrimary'>
								{`${moment(createdAt).format('Do MMMM YYYY')} at ${moment(
									createdAt
								).format('LT')}`}
							</Typography>
						</Box>
					</Box>
					<Box display='flex' gridGap={20}>
						<Box mt={2}>
							<Typography
								variant='caption'
								component='h4'
								color='textSecondary'
							>
								Priority
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{priority}
							</Typography>
							<Typography>{callNow ? 'Call Now' : 'Call Later'}</Typography>
						</Box>
						<Box display='flex' gridGap={40} mt={2}>
							{!callNow && (
								<>
									<Box>
										<Typography
											variant='caption'
											component='h4'
											color='textSecondary'
										>
											Slot Date
										</Typography>
										<Typography
											variant='body1'
											component='p'
											color='textPrimary'
										>
											{slotDate}
										</Typography>
									</Box>
									<Box>
										<Typography
											variant='caption'
											component='h4'
											color='textSecondary'
										>
											Slot Time
										</Typography>
										<Typography
											variant='body1'
											component='p'
											color='textPrimary'
										>
											{timeSlot}
										</Typography>
									</Box>
								</>
							)}
						</Box>
					</Box>
				</Box>
				<Box mt={3}>
					<Link
						to={`/tickets/active/details/${id}`}
						className={classes.viewBtn}
					>
						View Issue details
					</Link>
				</Box>
			</Link>
		</Card>
	)
}

export default ActiveTicketCard
