import {
	Box,
	Button,
	CircularProgress,
	TextField,
	Typography,
} from '@material-ui/core'
import {useEffect, useState} from 'react'
import {http} from '../api/http'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import {useHistory, Link} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {_isExpert} from '../shared/constants'

const ExpertProfile = () => {
	const history = useHistory()

	const {enqueueSnackbar} = useSnackbar()
	const [data, setData] = useState({
		name: '',
		mobile: '',
		roles: [],
	})
	const [loading, setLoading] = useState(false)
	const [formData, setFormData] = useState({
		designation: '',
		qualification: '',
		experience: '',
		mobile: '',
	})

	useEffect(() => {
		if (!_isExpert) {
			history.push('/')
		}
		_isExpert && getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/users/expert/profile`)
			.then((res) => {
				setData({
					...res.data,
					name: res.data.name,
					mobile: res.data.mobile,
					roles: res.data.roles,
				})
				setFormData({
					designation: res.data.designation,
					qualification: res.data.qualification,
					experience: res.data.experience,
					mobile: res.data.mobile,
				})
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	function handleSave(e) {
		e.preventDefault()
		http
			.patch(`/users/expert/profile`, formData)
			.then((res) => {
				getData()
				enqueueSnackbar('Changes Saved', {variant: 'success'})
			})

			.catch((err) => {
				enqueueSnackbar('Error Saving Details', {variant: 'error'})
			})
	}

	function handleChange(e) {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}

	if (loading) return <CircularProgress />

	return (
		<Box maxWidth='360px'>
			<Box display='flex' alignItems='center' gridGap={20}>
				<Link style={{lineHeight: 0}} onClick={() => history.goBack()}>
					<KeyboardBackspaceIcon />
				</Link>
				<Typography variant='h5' color='textPrimary'>
					Expert Profile
				</Typography>
			</Box>

			<form onSubmit={handleSave}>
				<Box display='flex' mt={4} gridGap={30} flexDirection='column'>
					<CustomTextField label='Name' value={data.name} disabled />
					<CustomTextField label='Mobile' value={data.mobile} disabled />
					<CustomTextField label='Address' value={data.address} disabled />
					<CustomTextField
						label='Qualification'
						name='qualification'
						value={formData.qualification}
						onChange={handleChange}
					/>
					<CustomTextField
						multiline
						label='Designation'
						name='designation'
						value={formData.designation}
						onChange={handleChange}
					/>
					<CustomTextField
						label='Experience'
						name='experience'
						value={formData.experience}
						onChange={handleChange}
					/>
					<Button
						type='submit'
						variant='contained'
						size='medium'
						color='primary'
					>
						Save
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default ExpertProfile

const CustomTextField = ({label, ...rest}) => {
	return (
		<TextField
			size='small'
			{...rest}
			variant='outlined'
			label={label}
			placeholder={label}
			fullWidth
		/>
	)
}
