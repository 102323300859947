import {
	Box,
	Card,
	Chip,
	Divider,
	Typography,
	Link as MatLink,
} from '@material-ui/core'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { labelColor } from '../../shared/constants'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 440,
		marginRight: '30px',
		marginTop: '20px',
		padding: 20,
		background: theme.palette.background.default,
	},
	viewBtn: {
		padding: '1ch 2ch',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: '3ch',
	},
}))

const ClosedTicketCard = ({ cardData }) => {
	const history = useHistory()
	const {
		name,
		createdAt,
		status,
		ticketId,
		ponds,
		animalVariety,
		type,
		userId,
	} = cardData
	const classes = useStyles()

	return (
		<Card className={classes.root} variant='outlined'>
			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				mb={1}
			>
				<Box>
					<Typography variant='caption' component='h4' color='textSecondary'>
						Issue Id
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{ticketId}
					</Typography>
				</Box>
				<Chip
					size='small'
					label={status}
					variant='default'
					style={{
						color: 'white',
						background: labelColor(status),
						paddingTop: '2px',
					}}
				/>
			</Box>
			<Divider />
			<Box display='flex' justifyContent='space-between' my={1}>
				<Box>
					<Typography variant='caption' component='h4' color='textSecondary'>
						Farmer Name
					</Typography>
					<MatLink
						color='primary'
						style={{ cursor: 'pointer' }}
						onClick={(e) => {
							e.preventDefault()
							e.stopPropagation()
							history.push(`/farmers/${userId}`)
						}}
					>
						{name}
					</MatLink>
				</Box>
				<Box>
					<Typography variant='caption' component='h4' color='textSecondary'>
						Requested Date
					</Typography>
					<Typography variant='body2' component='p' color='textPrimary'>
						{`${moment(createdAt).format('Do MMMM YYYY')} at ${moment(
							createdAt
						).format('LT')}`}
					</Typography>
				</Box>
			</Box>
			<Box mt={3}>
				<Box>
					<Typography variant='body1' component='p'>
						{ponds.length + ' Ponds'}
					</Typography>
				</Box>
				<Box>
					<Typography variant='body2' component='p' color='textSecondary'>
						{`${type} | ${animalVariety}`}
					</Typography>
				</Box>
			</Box>
		</Card>
	)
}

export default ClosedTicketCard

// const cardData = {
// 	id: 2,
// 	userId: 3,
// 	volunteerId: 6,
// 	volunteerName: 'raju',
// 	status: 'Closed',
// 	name: 'B SAI UTTEJ',
// 	mobile: '8919740766',
// 	address: null,
// 	ponds: [],
// 	type: 'Fish',
// 	animalVariety: 'catFish',
// 	issues: ['1', '2'],
// 	discription: null,
// 	pondPic: null,
// 	animalPic: null,
// 	waterReport: null,
// 	callNow: false,
// 	slotDate: '2021-08-21',
// 	timeSlot: null,
// 	expertId: null,
// 	expertName: null,
// 	createdAt: '2021-08-11T07:28:11.426Z',
// 	issuesDetails: [],
// 	prescriptions: [],
// }
