import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { SnackbarProvider } from 'notistack'
import AuthProvider from './context/AuthContext'
import App2 from './app2'
ReactDOM.render(
	<SnackbarProvider
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		maxSnack={3}
	>
		<AuthProvider>
			<App />
			{/* <App2 /> */}
			{/* <App3 /> */}
		</AuthProvider>
	</SnackbarProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
