import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Typography,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import MaterialTable, { MTableToolbar } from 'material-table'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { http } from '../../api/http'
import AssignVolunteer from './AssignVolunteer'
import { makeStyles } from '@material-ui/core/styles'
import { red, green } from '@material-ui/core/colors'
const useStyles = makeStyles((theme) => ({
	root: {},
	button: {
		padding: '8px',
		borderRadius: '5px',
		fontSize: '.8rem',
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: '20px',
	},
}))

function Farmers() {
	const classes = useStyles()
	const history = useHistory()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [selectedFarmerIds, setSelectedFarmerIds] = useState([])

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	function getData() {
		http
			.get('/kyc/admin')
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	if (loading) return <CircularProgress />

	return (
		<div className={classes.root}>
			<Box
				mb={2}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='subtitle1'>Farmers</Typography>
			</Box>

			<MaterialTable
				columns={[
					{
						title: 'Registered Date',
						field: 'user_created_at',
						render: (rowData) =>
							moment(rowData.user_created_at).format('MMM Do YYYY'),
					},
					{
						title: 'Requested Date',
						field: 'user_updated_at',
						render: (rowData) =>
							moment(rowData.user_updated_at).format('MMM Do YYYY'),
					},
					{ title: 'Name', field: 'user_name' },
					{ title: 'Mobile', field: 'user_mobile' },
					{ title: 'Address', field: 'user_address' },
					{
						title: 'City',
						render: (rowData) => rowData.user_address?.split(',').slice(-3)[0],
					},

					{
						title: 'KYC Status',
						field: 'kyc_kyc_verified',
						render: (rowData) =>
							rowData.kyc_kyc_verified ? (
								<Chip
									size='small'
									label='Done'
									variant='outlined'
									style={{
										background: 'green',
										color: 'white',
										border: 0,
										paddingTop: '2px',
									}}
								/>
							) : (
								<Chip
									size='small'
									label='Pending'
									variant='outlined'
									style={{
										background: 'red',
										color: 'white',
										border: 0,
										paddingTop: '2px',
									}}
								/>
							),
					},
					{ title: 'Field Volunteer', field: 'kyc_volunteer_name' },
				]}
				components={{
					Action: (props) => {
						console.log(props.data)
						if (props.action.icon === 'assign') {
							return (
								<Button
									className={classes.button}
									variant='contained'
									color='primary'
									onClick={(e) => {
										setSelectedFarmerIds(props.data.map((item) => item.user_id))
										setOpen(true)
									}}
									disabled={props.data.some(
										(item) =>
											item.kyc_kyc_verified == 1 ||
											item.kyc_kyc_verified == null ||
											!!item.kyc_volunteer_id
									)}
								>
									Assign Field Volunteer
								</Button>
							)
						}
						if (props.action.icon === 'view') {
							return (
								<Button
									className={classes.button}
									variant='contained'
									color='primary'
									onClick={(e) => {
										history.push(`/farmers/${props.data[0]?.user_id}`)
									}}
									disabled={
										props.data[0].kyc_kyc_verified === 0 ||
										props.data[0].kyc_kyc_verified === null ||
										props.data.length > 1
									}
								>
									View Profile
								</Button>
							)
						}
						return 'nothing'
					},
				}}
				isLoading={loading}
				actions={[{ icon: 'assign' }, { icon: 'view' }]}
				options={{
					actionsColumnIndex: -1,
					selection: true,
				}}
				data={data}
				title=''
			/>
			{selectedFarmerIds.length > 0 && (
				<AssignVolunteer
					selectedFarmerIds={selectedFarmerIds}
					open={open}
					setOpen={setOpen}
					refresh={getData}
				/>
			)}
		</div>
	)
}

export default Farmers
